import React, { Component } from "react";
import "./QuestThree.css";

export class QuestThree extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values, handleChange } = this.props;
    return (
      <div className="questThree__style">
        <h3 className="textStyle3">
          Have been a lawful permanent resident or citizen of other countries?
        </h3>
        <div className="uvsApp__yesNo">
          <div className="yes">
            <h2>YES</h2>
            <input
              name="resident"
              type="radio"
              value="yes"
              id="yes"
              onChange={handleChange("q01")}
              defaultValue={values.q01}
            />
          </div>
          <div className="no">
            <h2>NO</h2>
            <input
              name="resident"
              type="radio"
              id="no"
              value="no"
              onChange={handleChange("q01")}
              defaultValue={values.q01}
            />
          </div>
        </div>
        <div className="nextDiv">
          <svg
            className="uvsApp__nextButton"
            height="70"
            width="65"
            onClick={this.continue}
          >
            <polygon points="20,10 65,40 20,70" />
          </svg>
          <h4 className="uvsApp__nextButton" onClick={this.continue}>
            NEXT
          </h4>
        </div>
      </div>
    );
  }
}

export default QuestThree;
