import React, { Component } from "react";
import { db } from "../../App.js";
import { uid } from "uid";
import { set, ref } from "firebase/database";
import "./AppSubmit.css";

export class AppSubmit extends Component {
  continue = (e) => {
    e.preventDefault();

    // firebase submit
    const {
      values: { q01, q02, q03, q04, q05, q06, q07, q08, q09, q10, q11 },
    } = this.props;
    const uuid = uid();
    set(ref(db, `/${uuid}`), {
      q01,
      q02,
      q03,
      q04,
      q05,
      q06,
      q07,
      q08,
      q09,
      q10,
      q11,
      uuid,
    });

    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const {
      values: { q01, q02, q03, q04, q05, q06, q07, q08, q09, q10, q11 },
    } = this.props;
    return (
      <div className="appSubmit__style">
        <ol>
          <li>{q01}</li>
          <li>{q02}</li>
          <li>{q03}</li>
          <li>{q04}</li>
          <li>{q05}</li>
          <li>{q06}</li>
          <li>{q07}</li>
          <li>{q08}</li>
          <li>{q09}</li>
          <li>{q10}</li>
          <li>{q11}</li>
        </ol>
        <div className="backNextButtons">
          <div className="appNav__back">
            <svg
              className="uvsApp__nextButton"
              height="70"
              width="60"
              onClick={this.back}
            >
              <polygon points="50,10 5,40 50,70" />
            </svg>
            <h4 className="uvsApp__nextButton" onClick={this.back}>
              BACK
            </h4>
          </div>
          <div className="appNav__continue">
            <svg
              className="uvsApp__nextButton"
              height="70"
              width="65"
              onClick={this.continue}
            >
              <polygon points="20,10 65,40 20,70" />
            </svg>
            <h4 className="uvsApp__nextButton" onClick={this.continue}>
              SUBMIT
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default AppSubmit;
