import React, { Component } from "react";
import "./Application.css";
import AppSubmit from "./appQuestions/AppSubmit";
import AppThankYou from "./appQuestions/AppThankYou";
import QuestOne from "./appQuestions/QuestOne";
import QuestThree from "./appQuestions/QuestThree";
import QuestTwo from "./appQuestions/QuestTwo";
import QuestFour from "./appQuestions/QuestFour";
import QuestFive from "./appQuestions/QuestFive";
import QuestSix from "./appQuestions/QuestSix";
import QuestSeven from "./appQuestions/QuestSeven";
import QuestEight from "./appQuestions/QuestEight";
import QuestNine from "./appQuestions/QuestNine";

export class Application extends Component {
  state = {
    step: 1,
    q01: "",
    q02: "",
    q03: "",
    q04: "",
    q05: "",
    q06: "",
    q07: "",
    q08: "",
    q09: "",
    q10: "",
    q11: "",
  };

  //   proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };
  //   proceed to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  //   Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    const { step } = this.state;
    const { q01, q02, q03, q04, q05, q06, q07, q08, q09, q10, q11 } =
      this.state;
    const values = { q01, q02, q03, q04, q05, q06, q07, q08, q09, q10, q11 };
    switch (step) {
      case 1:
        return (
          <>
            <QuestOne
              nextStep={this.nextStep}
              handleChange={this.handleChange}
            />
          </>
        );
      case 2:
        return (
          <>
            <QuestTwo
              nextStep={this.nextStep}
              handleChange={this.handleChange}
            />
          </>
        );
      case 3:
        return (
          <>
            <QuestThree
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
            />
          </>
        );
      case 4:
        return (
          <>
            <QuestFour
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          </>
        );
      case 5:
        return (
          <>
            <QuestFive
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          </>
        );
      case 6:
        return (
          <>
            <QuestSix
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          </>
        );
      case 7:
        return (
          <>
            <QuestSeven
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          </>
        );
      case 8:
        return (
          <>
            <QuestEight
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          </>
        );
      case 9:
        return (
          <>
            <QuestNine
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
            />
          </>
        );
      case 10:
        return (
          <>
            <AppSubmit
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              values={values}
            />
          </>
        );

      case 11:
        return (
          <>
            <AppThankYou />
          </>
        );
    }
  }
}

export default Application;
