import React, { Component } from "react";
import "./QuestOne.css";

export class QuestOne extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    // const { values, handleChange } = this.props;
    return (
      <div className="questOne__style">
        <h1 className="textStyle1">WELCOME</h1>
        <h3 className="textStyle1">
          To the application for Certificate of Citizenship to the United
          Vegetable States
        </h3>

        <svg
          className="uvsApp__nextButton"
          height="70"
          width="65"
          onClick={this.continue}
        >
          <polygon points="20,10 65,40 20,70" />
        </svg>
        <h4 className="uvsApp__nextButton" onClick={this.continue}>
          NEXT
        </h4>
        {/* <button onClick={this.continue}>next</button> */}
      </div>
    );
  }
}

export default QuestOne;
