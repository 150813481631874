import React from "react";
import "./Links.css";

function Links() {
  return (
    <div className="links">
      <a href="https://www.hehlfireclub.org">
        <button>HEhL FIRE MEMBERSHIPS</button>
      </a>
      <a href="https://www.youtube.com/@lemieuxstudios">
        <button>YOUTUBE</button>
      </a>
      <a href="/">
        <button>Explore my world</button>
      </a>
    </div>
  );
}

export default Links;
