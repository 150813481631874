const iItCircles = [
  {
    color: "#1C342E",
    text: "Donkey Lives Long life",
    top: "63.25vw",
    left: "18.75vw",
  },
  { color: "#5E271D", text: "observation", top: "61vw", left: "54.75vw" },
  { color: "#DF99B5", text: "Compose Things", top: "83.5vw", left: "31.75vw" },
  { color: "#D2A53B", text: "Highland Cow", top: "75vw", left: "71vw" },
  {
    color: "#AECBC7",
    text: "Watch out for My Revenge Against you",
    top: "100.5vw",
    left: "15vw",
  },
  {
    color: "#D23021",
    text: "Don’t want to Fall asleep",
    top: "92vw",
    left: "52.5vw",
  },

  { color: "#95B2D5", text: "Your Truth", top: "118.25vw", left: "33.75vw" },
  {
    color: "#ED5D32",
    text: "Join Me To make A better World?",
    top: "114.25vw",
    left: "70.5vw",
  },
  {
    color: "#3F6D5B",
    text: "Believe In Details",
    top: "134.75vw",
    left: "15vw",
  },
  {
    color: "#A8ADC0",
    text: "Have you been to my dinner?",
    top: "138.25vw",
    left: "48.75vw",
  },
  {
    color: "#132C4E",
    text: "texture exist E---very- where",
    top: "156vw",
    left: "28.5vw",
  },
  {
    color: "#F1B168",
    text: "Used to be A Pianist",
    top: "152vw",
    left: "72.75vw",
  },
  {
    color: "#3C9D96",
    text: "Love to Quilt Odd things",
    top: "165.75vw",
    left: "57.5vw",
  },
  { color: "#DB3529", text: "Maker anything", top: "172.5vw", left: "12vw" },
  {
    color: "#2B66A0",
    text: "Coffee No milk No sugar",
    top: "193.5vw",
    left: "26.5vw",
  },
  {
    color: "#EEA4C8",
    text: "Night Night Movie(s)",
    top: "191vw",
    left: "48.75vw",
  },
  { color: "#D2A53B", text: "I have Many Stories", top: "195vw", left: "71vw" },
  { color: "#785E4C", text: "INFJ", top: "216vw", left: "24.25vw" },
  {
    color: "#DDC73E",
    text: "ALLERGIC To Things things",
    top: "226.25vw",
    left: "69vw",
  },
  {
    color: "#132C4E",
    text: "Quiet, But, I have OPINIONS",
    top: "236.25vw",
    left: "12vw",
  },
  {
    color: "#95B2D5",
    text: "Creator At Anywhere anytime",
    top: "242vw",
    left: "36.75vw",
  },
  {
    color: "#E35D36",
    text: "Dr. Pepper Is the BEST",
    top: "250vw",
    left: "59vw",
  },
];

export default iItCircles;
