import React, { Component } from "react";
import "./QuestNine.css";

export class QuestNine extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values, handleChange } = this.props;
    return (
      <div className="questNine__style">
        <h3 className="textStyle9">Enter your NEW BIRTHDAY</h3>
        <input
          type="date"
          onChange={handleChange("q11")}
          defaultValue={values.q11}
        />
        <div className="backNextButtons">
          <div className="appNav__back">
            <svg
              className="uvsApp__nextButton"
              height="70"
              width="60"
              onClick={this.back}
            >
              <polygon points="50,10 5,40 50,70" />
            </svg>
            <h4 className="uvsApp__nextButton" onClick={this.back}>
              BACK
            </h4>
          </div>
          <div className="appNav__continue">
            <svg
              className="uvsApp__nextButton"
              height="70"
              width="65"
              onClick={this.continue}
            >
              <polygon points="20,10 65,40 20,70" />
            </svg>
            <h4 className="uvsApp__nextButton" onClick={this.continue}>
              NEXT
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestNine;
