import React, { Component } from "react";
import "./QuestTwo.css";

export class QuestTwo extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    // const { values, handleChange } = this.props;
    return (
      <div className="questTwo__style">
        <h3 className="textStyleTwo">
          If your biological or legal adoptive mother or father is a UVSCIS
          citizen by birth, or was naturalized before you reached your 18th
          birthday, you may already be a U.V.S. Citizen. Before you consider
          filing this application, please meditate for more clarity in your
          mind.
        </h3>

        <svg
          className="uvsApp__nextButton"
          height="70"
          width="65"
          onClick={this.continue}
        >
          <polygon points="20,10 65,40 20,70" />
        </svg>

        <h4 className="uvsApp__nextButton" onClick={this.continue}>
          NEXT
        </h4>

        {/* <button onClick={this.continue}>next</button> */}
      </div>
    );
  }
}

export default QuestTwo;
