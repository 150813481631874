import React, { Component } from "react";
import "./QuestEight.css";

export class QuestEight extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values, handleChange } = this.props;
    return (
      <div className="questEight__style">
        <h3 className="textStyle8">What is your new name?</h3>
        <h3 className="textStyle8">
          (Type exactly as you wish it to appear on your citizenship card)
        </h3>
        <input
          type="text"
          placeholder="First Name"
          onChange={handleChange("q08")}
          defaultValue={values.q08}
        />
        <h4 className="textStyle8">First Name</h4>
        <input
          type="text"
          placeholder="Middle Name (If Applicable)"
          onChange={handleChange("q09")}
          defaultValue={values.q09}
        />
        <h4 className="textStyle8">Middle Name</h4>
        <input
          type="text"
          placeholder="Last Name"
          onChange={handleChange("q10")}
          defaultValue={values.q10}
        />
        <h4 className="textStyle8">Last Name</h4>

        <div className="backNextButtons8">
          <div className="appNav__back">
            <svg
              className="uvsApp__nextButton"
              height="70"
              width="60"
              onClick={this.back}
            >
              <polygon points="50,10 5,40 50,70" />
            </svg>
            <h4 className="uvsApp__nextButton" onClick={this.back}>
              BACK
            </h4>
          </div>
          <div className="appNav__continue">
            <svg
              className="uvsApp__nextButton"
              height="70"
              width="65"
              onClick={this.continue}
            >
              <polygon points="20,10 65,40 20,70" />
            </svg>
            <h4 className="uvsApp__nextButton" onClick={this.continue}>
              NEXT
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestEight;
