import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
// import { analytics, app } from "./firebase";

import Home from "./pages/Home";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Apage from "./pages/Apage";
import Bpage from "./pages/Bpage";
import Cpage from "./pages/Cpage";
import Dpage from "./pages/Dpage";
import You from "./pages/You";
import Iit from "./pages/Iit";
import SubHeader from "./components/SubHeader";
import Lost from "./pages/Lost";
import Modal1 from "./components/Modal1";
import Modal2 from "./components/Modal2";
import Modal3 from "./components/Modal3";
import Modal4 from "./components/Modal4";
import Application from "./veggie/Application";
import Circus from "./circus/Circus";
import Links from "./links/Links";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDd7c5WB5Z85zwR0ALA5fJQcApv9o_jVQw",
  authDomain: "yiseullemieux.firebaseapp.com",
  databaseURL: "https://yiseullemieux-default-rtdb.firebaseio.com",
  projectId: "yiseullemieux",
  storageBucket: "yiseullemieux.appspot.com",
  messagingSenderId: "284239759923",
  appId: "1:284239759923:web:4d60244e8ede6163633146",
  measurementId: "G-5Y4PLDTE06",
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const database = getDatabase(app);
export const db = getDatabase(app);

function App() {
  return (
    <div className="App">
      <Header />
      <Modal1 />
      <Modal2 />
      <Modal3 />
      <Modal4 />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="page" element={<SubHeader />}>
          <Route path="a" element={<Apage />} />
          <Route path="b" element={<Bpage />} />
          <Route path="c" element={<Cpage />} />
          <Route path="d" element={<Dpage />} />
          <Route path="you" element={<You />} />
          <Route path="iit" element={<Iit />} />
          <Route path="uvsapp" element={<Application />} />
          <Route path="circus" element={<Circus />} />
          <Route path="art" element={<Links />} />
          <Route path="*" element={<Lost />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
