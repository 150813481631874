const gallery = [
  {
    image: "/img/over the pics 11.png",
    alt: "grandma",
    legend: "#10325B",
    legend2: "#2972AE",
    legend3: "#7B221C",
  },
  {
    image: "/img/over the pics 10.png",
    alt: "mom",
    legend: "#10325B",
    legend2: "#2972AE",
    legend3: "#7B221C",
  },
  {
    image: "/img/SketchDay2.png",
    alt: "wool",
    legend: "#10325B",
    legend2: "#2972AE",
    legend3: "#7B221C",
  },
  {
    image: "/img/IMG_2047.png",
    alt: "ears",
    legend: "#DDC73E",
    legend2: "#2972AE",
    legend3: "",
    display3: "none",
    width: "unset",
    height: "unset",
  },
  {
    image: "/img/IMG_8401.png",
    alt: "paper art",
    legend: "#10325B",
    legend2: "#2972AE",
    legend3: "#7B221C",
  },

  {
    image: "/img/Scan day3 11.png",
    alt: "hand sewn",
    legend: "#10325B",
    legend2: "#2972AE",
    legend3: "#7B221C",
  },
  {
    image: "/img/Scan day6 6.png",
    alt: "scan",
    legend: "#10325B",
    legend2: "#2972AE",
    legend3: "#7B221C",
  },
  {
    image: "/img/pictureand pics the pics 6.png",
    alt: "grandma",
    legend: "#10325B",
    legend2: "#2972AE",
    legend3: "#7B221C",
  },
  {
    image: "/img/picture vertical.png",
    alt: "face",
    legend: "#2972AE",
    legend2: "",
    legend3: "",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_6775.png",
    alt: "face",
    legend: "#2972AE",
    legend2: "",
    legend3: "",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/Scan day6.png",
    alt: "ears",
    legend: "#10325B",
    legend2: "#2972AE",
    legend3: "#7B221C",
  },
  {
    image: "/img/Scan day7 7.png",
    alt: "scan",
    legend: "#10325B",
    legend2: "#2972AE",
    legend3: "#7B221C",
  },
  {
    image: "/img/DSC_0058.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/DSC_0694.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/6F36DFF5-2A34-4CB7-A148-AB99656BB004.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/IMG_6542.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/DSC_0603.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/IMG_6445.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/IMG_6566.png",
    alt: "jewelry",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/DSC_0672.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/DSC_0573.png",
    alt: "accessories",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/DSC_0011.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/DSC_0648.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/DSC_0521.png",
    alt: "clothing",
    legend: "#10325B",
    legend2: "#DB3529",
    legend3: "#2972AE",
  },
  {
    image: "/img/IMG_1180.png",
    alt: "face",
    legend: "#DDC73E",
    legend2: "#2972AE",
    display3: "none",
  },
  {
    image: "/img/IMG_1188.png",
    alt: "face",
    legend: "#DDC73E",
    legend2: "#2972AE",
    display3: "none",
  },
  {
    image: "/img/IMG_1118.png",
    alt: "face",
    legend: "#DDC73E",
    legend2: "#2972AE",
    display3: "none",
  },
  {
    image: "/img/IMG_1209.png",
    alt: "face",
    legend: "#DDC73E",
    legend2: "#2972AE",
    display3: "none",
  },
  {
    image: "/img/IMG_1897.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_1896.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_1894.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_1895.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/Untitled_Artwork 11.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_0930.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },

  {
    image: "/img/Untitled_Artwork 22.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/Untitled_Artwork 17.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_1226.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_1225.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/F279C9E4-497F-44A0-8CDB-606C5563F107.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/7CA31DEC-2EAE-448F-8607-5C97CC01CAD4.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_0931.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_2558.png",
    alt: "painting",
    legend: "#DDC73E",
    display2: "none",
    display3: "none",
  },
  {
    image: "/img/IMG_3611.png",
    alt: "ears",
    legend: "#10325B",
    legend2: "#2972AE",
    display3: "none",
  },
  {
    image: "/img/IMG_3585.png",
    alt: "ears",
    legend: "#10325B",
    legend2: "#2972AE",
    display3: "none",
  },
];

export default gallery;
