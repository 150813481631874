import React, { Component } from "react";
import "./AppThankYou.css";

export class AppThankYou extends Component {
  render() {
    return (
      <div className="appThankYou__style">
        <p className="textStyleThankYou">
          Thank you for filling out the application for citizenship for the
          United Vegetable States. You will be notified eventually with your
          status.
        </p>
      </div>
    );
  }
}

export default AppThankYou;
