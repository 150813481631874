import VisibilityIcon from "@mui/icons-material/Visibility";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import HeadphonesIcon from "@mui/icons-material/Headphones";

const component = [
  {
    textOne: "Felt Trap",
    textTwo: "2021 Piano",
    legend1: "#928F47",
    legend2: "#F1B168",
    display3: "none",
    legend4: <HeadphonesIcon className="headphones" />,
    link: "https://soundcloud.com/yiseul-lemieux/felt-trap?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
  },
  {
    textOne: "P3",
    textTwo: "2017 Piano",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/qmvNL7D_MgY",
  },
  {
    textOne: "Where the Smell Comes From",
    textTwo: "2021 Electronic",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    link: "https://youtu.be/ZSIuoNvt2_Q",
  },
  {
    textOne: "V1",
    textTwo: "2018 Clarinet",
    legend1: "#928F47",
    display2: "none",
    display3: "none",
    legend4: <HeadphonesIcon className="headphones" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://soundcloud.com/yiseul-lemieux/v1-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
  },
  {
    textOne: "Cathedral I-III",
    textTwo: "2017 Violin",
    legend1: "#928F47",
    display2: "none",
    display3: "none",
    legend4: <HeadphonesIcon className="headphones" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://soundcloud.com/yiseul-lemieux/clarinet-1-cathedral-1st-movement?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
  },
  {
    textOne: "K1",
    textTwo: "2017 Flute and Piano",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/wnVaNsbj_eM",
  },
  {
    textOne: "Lullaby",
    textTwo: "2020 Soprano, Harpsichord & toy piano",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    legend3: "#A8ADC0",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/xsRvYWzMffw",
  },
  {
    textOne: "P2",
    textTwo: "2017 Piano",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/H-gO66QOEps",
  },
  {
    textOne: "Dal",
    textTwo: "2017 Soprano, Violin, & Guitar",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/rB8TYrsjjoE",
  },
  {
    textOne: "B3",
    textTwo: null,
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/jBIQGSwti3k",
  },
  {
    textOne: "A Jelly Fish",
    textTwo: "2018 Soprano and Piano",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VisibilityIcon className="eye" />,
  },
  {
    textOne: "Milk Tea",
    textTwo: "2021 Voice and Guitar",
    legend1: "#34AAA1",
    display2: "none",
    display3: "none",
    legend4: <VisibilityIcon className="eyeRed" />,
  },
  {
    textOne: "Movement of the Light",
    textTwo: "2018 String Quartet",
    legend1: "#34AAA1",
    display2: "none",
    display3: "none",
    legend4: <VisibilityIcon className="eye" />,
  },
  {
    textOne: "G1-6",
    textTwo: "2013-2017 Guitar",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/Yt7ikda6SxQ",
  },
  {
    textOne: "B1",
    textTwo: "2017 Cello",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/0bswGxkg_Xo",
  },
  {
    textOne: "Rabbit",
    textTwo: "2019 Piano, E. Guitar & Drum",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eyeRed" />,
    link: "https://youtu.be/sJT7FaLrhp4",
  },
  {
    textOne: "Mr. Chair",
    textTwo: "2017",
    legend1: "#34AAA1",
    legend2: "#B0412F",
    display3: "none",
  },
  {
    textOne: "Go I.",
    textTwo: "2020",
    display1: "none",
    display2: "none",
    display3: "none",
  },
  {
    textOne: "Ice Cream Truck",
    textTwo: "2019 Trumpet",
    legend1: "#EFC2C2",
    legend2: "#B0412F",
    legend3: "#34AAA1",
  },
  {
    textOne: "P2",
    textTwo: "2017",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
  },
  {
    textOne: "Stretching Routine",
    textTwo: "2019 Flute",
    legend1: "#EFC2C2",
    legend2: "#B0412F",
    legend3: "#34AAA1",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/rLDcZtkBRB8",
  },
  {
    textOne: "P4",
    textTwo: "2017 Piano",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/fG-Nkd1W5pY",
  },
  {
    textOne: "EE",
    textTwo: "2017",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/hUzFE70V7nE",
  },
  {
    textOne: "K2",
    textTwo: "2017",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eye" />,
    link: "https://youtu.be/OtZmSLuONVY",
  },
  {
    textOne: "H1",
    textTwo: "2018 Horn",
    legend1: "#34AAA1",
    display2: "none",
    display3: "none",
  },
  {
    textOne: "I Lines",
    textTwo: "2018 Sorprano, Horn, & Piano",
    legend1: "#34AAA1",
    display2: "none",
    display3: "none",
  },
  {
    textOne: "Ennui",
    textTwo: "2018 Sorprano",
    legend1: "#34AAA1",
    display2: "none",
    display3: "none",
  },
  {
    textOne: "Stupid Dory",
    textTwo: "2017",
    legend1: "#EFC2C2",
    legend2: "#B0412F",
    legend3: "#34AAA1",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eyeRed" />,
    link: "",
  },
  {
    textOne: "Fish Savage",
    textTwo: "2017",
    legend1: "#EFC2C2",
    legend2: "#B0412F",
    legend3: "#34AAA1",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eyeRed" />,
    link: "https://youtu.be/zyVsI0AkUi0",
  },
  {
    textOne: "Mermaid",
    textTwo: "2017",
    legend1: "#EFC2C2",
    legend2: "#B0412F",
    legend3: "#34AAA1",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eyeRed" />,
    link: "https://youtu.be/fM70uPsMLis",
  },
  {
    textOne: "Waive",
    textTwo: "2017",
    legend1: "#EFC2C2",
    legend2: "#B0412F",
    legend3: "#34AAA1",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eyeRed" />,
    link: "https://youtu.be/orJhWPmjnnY",
  },
  {
    textOne: "Dear Sebastian",
    textTwo: "2017",
    legend1: "#EFC2C2",
    legend2: "#B0412F",
    legend3: "#34AAA1",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eyeRed" />,
    link: "",
  },
  {
    textOne: "Scarlett",
    textTwo: "2017",
    legend1: "#B0412F",
    legend2: "#34AAA1",
    display3: "none",
  },
  {
    textOne: "Smoke III.",
    textTwo: "2017",
    legend1: "#B0412F",
    legend2: "#34AAA1",
    display3: "none",
  },
  {
    textOne: "FG1",
    textTwo: "2018",
    display1: "none",
    display2: "none",
    display3: "none",
  },
  {
    textOne: "Scatter, Together",
    textTwo: "2020",
    legend1: "#928F47",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VideoLibraryIcon className="play" />,
    legend5: <VisibilityIcon className="eyeRed" />,
    link: "https://youtu.be/VNFzW2-h274",
  },
  {
    textOne: "V3",
    textTwo: "2018 Violin",
    legend1: "#EFC2C2",
    legend2: "#34AAA1",
    display3: "none",
    legend4: <VisibilityIcon className="eye" />,
  },
  {
    textOne: "Summer to Spring Set",
    textTwo: "2017",
    legend1: "#EFC2C2",
    legend2: "#928F47",
    display3: "none",
  },
  {
    textOne: "Minion Launcher",
    textTwo: "2014",
    display1: "none",
    display2: "none",
    display3: "none",
  },
  {
    textOne: "Place of Articulation",
    textTwo: "2020 Voice",
    legend1: "#928F47",
    display2: "none",
    display3: "none",
    legend4: <VisibilityIcon className="eyeRed" />,
  },
  {
    textOne: "Brush Theme song",
    textTwo: "2021 Voice",
    legend1: "#928F47",
    legend2: "#34AAA1",
    legend3: "#B0412F",
  },
  {
    textOne: "Lemonade",
    textTwo: "2020 Voice",
    display1: "none",
    display2: "none",
    display3: "none",
  },
  {
    textOne: "Space Camping",
    textTwo: "2020 Voice",
    display1: "none",
    display2: "none",
    display3: "none",
  },
  {
    textOne: "Reconnection Piano Variations by Aaron Copland",
    textTwo: "2020",
    legend1: "#928F47",
    legend2: "#F1B168",
    display3: "none",
    legend4: <HeadphonesIcon className="headphones" />,
    link: "",
  },
];

export default component;
