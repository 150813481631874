import React, { Component } from "react";
import "./QuestFive.css";

export class QuestFive extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values, handleChange } = this.props;
    return (
      <div className="questFive__style">
        <h3 className="textStyle5">Are you allergic to certain vegetable?</h3>

        <div className="uvsApp__yesNo5">
          <div className="uvsApp__yesNo">
            <div className="yes">
              <h2>YES</h2>
              <input
                className="radioApplication5"
                name="vegetables"
                type="radio"
                value="yes"
                id="yes"
                onChange={handleChange("q03")}
                defaultValue={values.q03}
              />
            </div>
            <div className="no">
              <h2>NO</h2>
              <input
                className="radioApplication5"
                name="vegetables"
                type="radio"
                id="no"
                value="no"
                onChange={handleChange("q03")}
                defaultValue={values.q03}
              />
            </div>
          </div>
          <div className="backNext">
            <div className="appNav__back">
              <svg
                className="uvsApp__nextButton"
                height="70"
                width="60"
                onClick={this.back}
              >
                <polygon points="50,10 5,40 50,70" />
              </svg>
              <h4 className="uvsApp__nextButton" onClick={this.back}>
                BACK
              </h4>
            </div>
            <div className="appNav__continue">
              <svg
                className="uvsApp__nextButton"
                height="70"
                width="65"
                onClick={this.continue}
              >
                <polygon points="20,10 65,40 20,70" />
              </svg>
              <h4 className="uvsApp__nextButton" onClick={this.continue}>
                NEXT
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuestFive;
