const aPageVideos = [
  {
    url: "https://www.youtube.com/embed/3_PAJpnfmEk?si=VM-GBXfuxAluZPaW",
    title: "The Butt-Cracker",
    year: "2023",
    description: "",
    legend: "#D2A53B",
    legend2: null,
    display2: "none",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/ADZYn3cOp-o",
    title: "Self Expression",
    year: "2021",
    description:
      "For those who are not accepting Me (it, Yiseul) as who I truly am, or who are pretending to have an open mind, This project is my own protest against them. For those who do not know me, and coincidentally Found this project, it will introduce A part of myself.",
    legend: "#16463B",
    legend2: "#EEA4C8",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/DfUSB5VqK44",
    title: "These Patterns",
    year: "2022",
    description: "",
    legend: "#D2A53B",
    legend2: "#ED5D32",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/2Whun0iX3kE?",
    title: "Toothbrush",
    year: "2021",
    description:
      "Did you brush.your teeth today? What is so special about brushing teeth EVERYDAY? if only, brushing teeth can wash Off all the bad and dirty words you hear, Speak, and think, then Everyday, once a day Twice day brushing teeth could be more Helpful, hopeful, and precious.",
    legend: "#EEA4C8",
    legend2: null,
    display2: "none",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/f6ZXpt8rw-0",
    title: "Control and Controlled",
    year: "2022",
    description:
      "A new version is coming soon. Until then, watch this old version. Haha.",
    legend: "#D2A53B",
    legend2: "#2B61D5",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/82syKDwtdt4",
    title: "Garlic Etude V6X",
    year: "2021",
    description:
      "Over the years, your body develops habits or uses muscle memory that was developed and Practiced on your instrument. Not just my fingers, But also my ears are finding comfort with certain sounds. Such a frustrating feeling. Or does it make you a solid performer?",
    legend: "#16463B",
    legend2: "#D2A53B",
    legend3: "#2B61D5",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/0jKOm35Oht0",
    title: "10:30",
    year: "2020",
    description:
      "How do we approach a problem like global warming? Environmental issues? What is going to fix the problem? Do you think people will stop using to-go cups Because Starbucks won’t allow them? I don’t think so.",
    legend: "#D2A53B",
    legend2: "#95B2D5",
    legend3: "#EEA4C8",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/DNvE-JB0-EI",
    title: "Portable Farm",
    year: "2021",
    description:
      "Hundred percent organic Made, Squeezed, packaged By Yiseul LeMieux",
    legend: "#16463B",
    legend2: "#D2A53B",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/hZEvotepB5U",
    title: "With Pumpernickel",
    year: "2021",
    description: "Watch this video as well As Word Freedom",
    legend: "#16463B",
    legend2: "#D2A53B",
    legend3: "#95B2D5",
    legend4: "#EEA4C8",
  },
  {
    url: "https://www.youtube.com/embed/BNuMJ0b-SMQ",
    title: "Index of Heartbreak Ch.1",
    year: "2020",
    description:
      "Who limits the definition of heartbreak? A Heart can be broken by many things. It does not just mean a bad break up. Your heart, any(ones) (things) heart can be Damaged depending on where you put your heart. Big or small. Please, no judgement. It happens all the time.",
    legend: "#D2A53B",
    legend2: "#95B2D5",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/LUXAjPAdfjc",
    title: "Garlic Etude AAA",
    year: "2021",
    description:
      "I have to see how far I can take Garlic With the frustration towards my instrument.",
    legend: "#16463B",
    legend2: "#D2A53B",
    legend3: "#2B61D5",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/1d-1y0pnBjk",
    title: "Bubble Diary",
    year: "2021",
    description:
      "Daily BUBBLE journal. 1-10 10 straight days. Blowing bubbles everyday for 10 days On the street and through open windows.",
    legend: "#16463B",
    legend2: "#D2A53B",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/kqSYKXT6K9w",
    title: "The Ultimate Manual",
    year: "2021",
    description: "",
    legend: "#16463B",
    legend2: "#D2A53B",
    legend3: "#ED5D32",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://player.vimeo.com/video/435899721?h=fb9e9ca7b4",
    title: "More than Tempestuous",
    year: "2020",
    description:
      "Have you read about Prokofiev? He was very much a mommy’s boy. I cannot imagine myself working with Someone like him, but Haha. I did.",
    legend: "#D2A53B",
    legend2: "#EEA4C8",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/5n7JpgiRfFY",
    title: "Garlic Etude 000.00",
    year: "2021",
    description:
      "000.00 Is this a measurement? Finding a suitable measurement for garlic, it’s performance.",
    legend: "#16463B",
    legend2: "#D2A53B",
    legend3: "#2B61D5",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/sJT7FaLrhp4",
    title: "Rabbit",
    year: "2019",
    description: "The instructions carefully said No BUNNY.",
    legend: "#16463B",
    legend2: "#95B2D5",
    legend3: "#EEA4C8",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/rLDcZtkBRB8",
    title: "Stretching Routine",
    year: "2019",
    description:
      "Chickens do self-care. Whatever that means to you, Chickens have early morning yoga sessions.",
    legend: "#16463B",
    legend2: "#95B2D5",
    legend3: "#EEA4C8",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/zyVsI0AkUi0",
    title: "Fish Savage",
    year: "2019",
    description:
      "Full of scary bloody creatures under water. It’s easy to imagine things and make yourself terrified. Don’t go in the water.",
    legend: "#16463B",
    legend2: "#95B2D5",
    legend3: "#EEA4C8",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/2-B0jYDDwnw",
    title: "Here’s the Skinny” & SENP",
    year: "2020",
    description:
      "Interesting experience collaborating with Ches Smith. Never tried in-N-out burger. Curious?",
    legend: "#D2A53B",
    legend2: null,
    display2: "none",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/xsRvYWzMffw",
    title: "Lullaby",
    year: "2020",
    description: "",
    legend: "#16463B",
    legend2: "#EEA4C8",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/fM70uPsMLis",
    title: "Mermaid",
    year: "2020",
    description: "",
    legend: "#16463B",
    legend2: "#95B2D5",
    legend3: "#EEA4C8",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/dcKvdvPEB9M",
    title: "Stretching Routine",
    year: "2019",
    description:
      "More chickens and more players. Celebrating everyone’s imagination by the chicken coop.",
    legend: "#16463B",
    legend2: "#95B2D5",
    legend3: "#EEA4C8",
    legend4: null,
    display4: "none",
  },
  {
    url: "https://www.youtube.com/embed/X_QzCokEpio",
    title: "Go II.",
    year: "2021",
    description: "AT least give everyone a chance? One chance?",
    legend: "#16463B",
    legend2: "#D2A53B",
    legend3: null,
    display3: "none",
    legend4: null,
    display4: "none",
  },
];

export default aPageVideos;
