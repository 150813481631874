import React from "react";
import "./Circus.css";

function circus() {
  return (
    <div className="containerCircus">
      <div className="circusText">
        <p>
          The Circus is Coming to Town is an overwhelmingly interactive,
          respectably, aggressively engaging event full of surprises and prizes.
          You and your imagination will be featured at The Circus is Coming to
          Town. Think you would like to try something different? – The Circus
          Times : Yiseul LeMieux
        </p>
      </div>
      <img id="flyer" src="/img/brickFlyer.jpeg" alt="circus flyer" />
      <a href="https://ci.ovationtix.com/122/production/1191684" target="blank">
        <button className="getTickets">GET TICKETS</button>
      </a>
    </div>
  );
}

export default circus;
